import React, { useRef, useEffect, useState } from "react";
import ReactPlayer from "react-player";

export default function VideoPlayer(props) {
  const [videoPlaying, setVideoPlaying] = useState(false);
  const [muted, setMuted] = useState(true);
  const [volume, setVolume] = useState(0); // Volume state
  const playerContainerRef = useRef(null);

  useEffect(() => {
    const observer = new IntersectionObserver(
      ([entry]) => {
        if (!entry.isIntersecting) {
          let prevDecreaseChange = 0.05;
          let volumeInterval = setInterval(() => {
            setVolume((prevVolume) => {
              if (prevVolume > 0) {
                let newDecreaseVol = prevVolume - prevDecreaseChange;
                prevDecreaseChange = prevDecreaseChange / 0.9;
                newDecreaseVol = Number(newDecreaseVol.toPrecision(1));
                return newDecreaseVol < 0 ? 0 : newDecreaseVol;
              } else {
                clearInterval(volumeInterval);
                setVideoPlaying(false);
                return 0;
              }
            });
          }, 20);
        } else {
          setVideoPlaying(true);
          setMuted(false);
          let prevChange = 0.05;
          let volumeInterval = setInterval(() => {
            setVolume((prevVolume) => {
              if (prevVolume < 1) {
                let newVol = prevVolume + prevChange;
                prevChange = prevChange * 1.5;
                newVol = Number(newVol.toPrecision(2));
                return newVol > 1 ? 1 : newVol;
              } else {
                clearInterval(volumeInterval);
                return 1;
              }
            });
          }, 60);
        }
      },
      { threshold: 0.1 }
    );

    if (playerContainerRef.current) {
      observer.observe(playerContainerRef.current);
    }

    return () => {
      if (playerContainerRef.current) {
        observer.unobserve(playerContainerRef.current);
      }
    };
  }, []);

  return (
    <div
      ref={playerContainerRef}
      style={{
        height: "100%",
        width: "100%",
        overflow: "hidden",
        background: "#5f5f5f"
      }}
      onClick={() => setVideoPlaying(!videoPlaying)}
    >
      <ReactPlayer
        url={props.url}
        playing={videoPlaying}
        volume={volume}
        muted={muted}
        onPlay={() => setVideoPlaying(true)}
        onPause={() => setVideoPlaying(false)}
        wrapper={PlayerWrapper}
        config={{
          youtube: {
            playerVars: { 
              showinfo: 0, 
              controls: 0,
              enablejsapi: 1, 
            },
          },
        }}
        {...props}
      />
    </div>
  );
}

function PlayerWrapper(props) {
  return (
    <div
      style={{
        height: "100%",
        width: "100%",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
       
      }}
    >
      {props.children}
    </div>
  );
}
