export default function Gallery(props) {
  return (
    <div className="snap-x snap-mandatory h-screen overflow-x-scroll flex flex-row ">
      {props.children.map((item) => (
        <div className="snap-center w-screen h-screen flex items-center justify-center flex-none" key={item.key}>
          {item}
        </div>
      ))}
    </div>
  );
}
