import React, { useRef, useEffect, useState } from "react";
import ReactPlayer from "react-player";

export default function FullScreenMusicPlayer({
  src,
  alblumArt,
  title,
  artist,
  alblum,
  bgColor1,
  bgColor2,
}) {
  const [videoPlaying, setVideoPlaying] = useState(false);
  const [playedSeconds, setplayedSeconds] = useState(0);
  const [muted, setMuted] = useState(true);
  const [volume, setVolume] = useState(0); // Volume state
  const playerContainerRef = useRef(null);
  const [rotation, setRotation] = useState(0); // Rotation state
  const imageRef = useRef(null);

  useEffect(() => {
    const observer = new IntersectionObserver(
      ([entry]) => {
        if (!entry.isIntersecting) {
          let prevDecreaseChange = 0.05;
          let volumeInterval = setInterval(() => {
            setVolume((prevVolume) => {
              if (prevVolume > 0) {
                let newDecreaseVol = prevVolume - prevDecreaseChange;
                prevDecreaseChange = prevDecreaseChange / 0.9;
                newDecreaseVol = Number(newDecreaseVol.toPrecision(1));
                return newDecreaseVol < 0 ? 0 : newDecreaseVol;
              } else {
                clearInterval(volumeInterval);
                setVideoPlaying(false);
                return 0;
              }
            });
          }, 20);
        } else {
          setVideoPlaying(true);
          setMuted(false);
          let prevChange = 0.05;
          let volumeInterval = setInterval(() => {
            setVolume((prevVolume) => {
              if (prevVolume < 1) {
                let newVol = prevVolume + prevChange;
                prevChange = prevChange * 1.5;
                newVol = Number(newVol.toPrecision(2));
                return newVol > 1 ? 1 : newVol;
              } else {
                clearInterval(volumeInterval);
                return 1;
              }
            });
          }, 60);
        }
      },
      { threshold: 0.1 }
    );

    if (playerContainerRef.current) {
      observer.observe(playerContainerRef.current);
    }

    return () => {
      if (playerContainerRef.current) {
        observer.unobserve(playerContainerRef.current);
      }
    };
  }, []);

  useEffect(() => {
    let rotationIntervalId;

    const rotateImage = () => {
      setRotation((prevRotation) => {
        const newRotation = (prevRotation + 0.2) % 360; // 1.8 degrees per 100ms for a full rotation in 20 seconds
        if (imageRef.current) {
          imageRef.current.style.transform = `rotate(${newRotation}deg)`;
        }
        return newRotation;
      });
    };

    if (videoPlaying) {
      rotationIntervalId = setInterval(rotateImage, 10); // Update every 100ms
    } else {
      clearInterval(rotationIntervalId);
    }

    return () => clearInterval(rotationIntervalId);
  }, [videoPlaying]);

  return (
    <div
      ref={playerContainerRef}
      style={{
        height: "100%",
        width: "100%",
        overflow: "hidden",
      }}
    >
      <div
        className="w-screen h-screen flex items-center justify-center appearance-none"
        style={{
          background: `radial-gradient(circle, ${bgColor1} 0%, ${bgColor2}) 100% `,
          backgroundcolor: bgColor1,
        }}
      >
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
            color: "white",
            textAlign: "center",
            width: "100%",
          }}
        >
          <Disc alblumArt={alblumArt} setVideoPlaying={setVideoPlaying} videoPlaying={videoPlaying} imageRef={imageRef} />
          <div
            style={{
              marginTop: "1rem",
              userSelect: "none",
            }}
          >
            <h1 className="text-2xl font-bold">{title}</h1>
            <h2 className="text-lg">{artist}</h2>
          </div>
        </div>
      </div>
      <ReactPlayer
        url={src}
        playing={videoPlaying}
        volume={volume}
        height={"100%"}
        width={"100%"}
        muted={muted}
        onPlay={() => setVideoPlaying(true)}
        onPause={() => setVideoPlaying(false)}
        onProgress={(state) => {
          setplayedSeconds(state.playedSeconds);
        }}
      />
    </div>
  );
}

function Disc({ alblumArt, setVideoPlaying, videoPlaying, imageRef }) {
  return (
  <div
    ref={imageRef}
    style={{
         backgroundImage: `url(${alblumArt})`,
      backgroundSize: "100%",
      backgroundPosition: "center",
      borderRadius: "50%",
      borderRadius: "50%",
      width: "80%",
      height: "100%",
      aspectRatio: "1/1",
    }}
    onClick={() => setVideoPlaying((prev) => !prev)}
  ></div>
    // <div
    // ref={imageRef}
    // style={{
    //   backgroundImage: `url(${alblumArt})`,
    //   backgroundSize: "100%",
    //   backgroundPosition: "center",
    //   borderRadius: "50%",
    //   minHeight: "100%",
    //   minWidth: "100%",
    //   display: "block",
    //   cursor: "none",
    //   transform: videoPlaying ? "rotate(1deg)" : "rotate(0deg)",
    // }}
    // >
    // </div>
    // <img
    //   src={alblumArt}
    //   ref={imageRef}
    //   alt="alblum art"
    //   style={{ borderRadius: "50%", maxWidth: "70%", maxHeight: "70%" }}
    //   className={videoPlaying ? "rotating-image" : ""}
    //   onClick={() => setVideoPlaying((prev) => !prev)}
    // />
  );
}
