import Tagline from '../components/TagLine';

export default function TagLineSlide() {
    return(
        <header className="App-header w-screen h-screen">
          <div className="logobox">
            <div className="logo">- desired -</div>
            <div className="tagline">
              <Tagline />
            </div>
          </div>
        </header>
    )
}