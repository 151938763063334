import TypeIt from "typeit-react";
import { useEffect, useRef } from "react";

export default function Tagline() {
  const playerContainerRef = useRef(null);

  // Debounce function
  const debounce = (func, wait) => {
    let timeout;
    return (...args) => {
      clearTimeout(timeout);
      timeout = setTimeout(() => func.apply(this, args), wait);
    };
  };

  useEffect(() => {
    const handleIntersection = ([entry]) => {
      if (entry.isIntersecting) {
        if (document.allowFullScreen) {
          document.exitFullscreen().catch((err) => {
            // console.error(`Error attempting to exit full-screen mode: ${err.message} (${err.name})`);
          });
        }
      } else {
        if (!document.allowFullScreen) {
          document.documentElement.requestFullscreen().catch((err) => {
            // console.error(`Error attempting to enable full-screen mode: ${err.message} (${err.name})`);
          });
        }
      }
    };

    const debouncedHandleIntersection = debounce(handleIntersection, 200);

    const observer = new IntersectionObserver(debouncedHandleIntersection, { threshold: 0.1 });

    if (playerContainerRef.current) {
      observer.observe(playerContainerRef.current);
    }

    return () => {
      if (playerContainerRef.current) {
        observer.unobserve(playerContainerRef.current);
      }
    };
  }, []);

  return (
    <div ref={playerContainerRef}>
      <TypeIt
        getBeforeInit={(instance) => {
          instance.type("any");
          return instance;
        }}
      />
    </div>
  );
}