import "./App.css";
import VideoPlayer from "./components/VideoPlayer";
import Gallery from "./components/Gallery";
import FullScreenImage from "./components/FullScreenImage";
import Slide from "./components/Slide";
import TagLineSlide from "./slides/TagLineSlide";
import EmbeddedSpotify from "./components/EmbeddedSpotify";
import MusicGallery from "./slides/MusicGallery";
import DesiredOnRotationSlide from "./slides/DesiredOnRotation";

function App() {
  return (
    <div className="App">
      <Snap>
        <TagLineSlide />
        <Slide>
          <VideoPlayer
            url={
              "https://desiredstore.blob.core.windows.net/media/caseyneistat_life/playlist.m3u8"
            }
            loop
          />
        </Slide>
        <Gallery>
          <FullScreenImage
            src="https://s3.amazonaws.com/standardsmanualimages/product-nycta/NYCTA_GSM_18-2400.jpg"
            key={"1"}
          />
          <FullScreenImage
            src="https://s3.amazonaws.com/standardsmanualimages/gallery/original_002-2500.jpg"
            key={"2"}
          />
          <FullScreenImage
            src="https://s3.amazonaws.com/standardsmanualimages/product-nycta/NYCTA_GSM_6-2400.jpg"
            key={"3"}
          />
          <FullScreenImage
            src="https://s3.amazonaws.com/standardsmanualimages/product-nycta/NYCTA_GSM_DETAIL_16-2400.jpg"
            key={"4"}
          />
        </Gallery>

        <MusicGallery />

        <Slide>
          <VideoPlayer
            url={"https://www.youtube.com/watch?v=H6lWMsMjTgI"}
            
          />
        </Slide>
        <Slide>
          <FullScreenImage src="images/maxresdefault.jpg" />
        </Slide>
        <Slide>
          <FullScreenImage src="images/Timberland2.jpg" />
        </Slide>
        <Slide>
          <FullScreenImage src="images/gandtengland.jpg" />
        </Slide>
        {/* <Slide>
          <div
            className="w-screen h-screen flex items-center justify-center"
            style={{
              background:
                "radial-gradient(rgba(93,188,231,1) 0%, rgba(93,188,231,1) 16%, rgba(63,126,155,1) 100%)",
              backgroundcolor: "#3f7e9b",
            }}
          >
            <EmbeddedSpotify src="https://open.spotify.com/embed/track/5MWA0KkMh18hC2BkcCs4me" />
          </div>
        </Slide> */}
        {/* <Slide>
          <FullScreenImage src="images/mix3_1920x.jpg" />
        </Slide> */}

        <DesiredOnRotationSlide />
       
        <Slide>
          <VideoPlayer
            url={
              "https://www.youtube.com/playlist?list=PLdRxzzDAn5LrdPwYI4-2QSJn05t92KfgN"

            }
            controls
          />
        </Slide>
      </Snap>
    </div>
  );
}

export default App;

function Snap(props) {
  return (
    <div className="snap-y snap-mandatory h-screen w-screen mx:auto overflow-x-hidden">
      {props.children.map((item) => (
        <div className="snap-center w-screen h-screen flex items-center justify-center">
          {item}
        </div>
      ))}
    </div>
  );
}
