import React from "react";
import Slide from "../components/Slide";
import Gallery from "../components/Gallery";
import FullScreenMusicPlayer from "../components/FullScreenMusicPlayer";

export default function MusicGallery() {
  const songs = [
    {
      src: "https://desiredstore.blob.core.windows.net/media/Music/Feel%20Something.mp3",
      alblumArt: "https://desiredstore.blob.core.windows.net/media/Music/Feel%20Something.jpg",
      title: "Feel Something",
      artist: "Fiji Blue",
      bgColor1: "#296385",
      bgColor2: "#06131a",
    },
    {
      src: "https://desiredstore.blob.core.windows.net/media/Music/Punch%20The%20Brakes.mp3",
      alblumArt: "https://desiredstore.blob.core.windows.net/media/Music/Punch%20The%20Breaks.jpg",
      title: "Punch The Brakes",
      artist: "Christian French",
      bgColor1: "#1f090b",
      bgColor2: "#600306",
    },
    {
        src: "https://desiredstore.blob.core.windows.net/media/Music/Melt.mp3",
        alblumArt: "https://desiredstore.blob.core.windows.net/media/Music/Melt.jpg",
        title: "Melt",
        artist: "Quinn XCII",
        bgColor1: "#a7521a",
        bgColor2: "#c8b891",
      },
      {
        src: "https://desiredstore.blob.core.windows.net/media/Music/Die%20Right%20Here.mp3",
        alblumArt: "https://desiredstore.blob.core.windows.net/media/Music/Die%20Right%20Here.jpg",
        title: "Die Right Here",
        artist: "David Hugo",
        bgColor1: "#6a93a5",
        bgColor2: "#c88155",
      },
      {
        src: "https://desiredstore.blob.core.windows.net/media/Music/The%20Cool%20Kid.mp3",
        alblumArt: "https://desiredstore.blob.core.windows.net/media/Music/The%20Cool%20Kid.jpg",
        title: "The Cool Kid",
        artist: "Chris James",
        bgColor1: "#abaaed",
        bgColor2: "#e6d1e4",
      },
      {
        src: "https://desiredstore.blob.core.windows.net/media/Music/Kids%20Are%20Born%20Stars.mp3",
        alblumArt: "https://desiredstore.blob.core.windows.net/media/Music/Kids%20Are%20Born%20Stars.jpg",
        title: "Kids Are Born Stars",
        artist: "Lauv",
        bgColor1: "#030f1f",
        bgColor2: "#0f283c",
      },
      {
        src: "https://desiredstore.blob.core.windows.net/media/Music/WHAT%20WOULD%20YOU%20DO%20(feat.%20Pink%20Sweat$).mp3",
        alblumArt: "https://desiredstore.blob.core.windows.net/media/Music/What%20Would%20You%20Do.jpg",
        title: "WHAT WOULD YOU DO (feat. Pink Sweat$)",
        artist: "HONNE",
        bgColor1: "#ab6d60",
        bgColor2: "#421826",
      },
    //   {
    //     src: "",
    //     alblumArt: "",
    //     title: "",
    //     artist: "",
    //     bgColor1: "",
    //     bgColor2: "",
    //   },
  ];

  return (
    <Slide>
      <Gallery>
        {songs.map((song, index) => (
          <FullScreenMusicPlayer
            key={index}
            src={song.src}
            alblumArt={song.alblumArt}
            title={song.title}
            artist={song.artist}
            bgColor1={song.bgColor1}
            bgColor2={song.bgColor2}
          />
        ))}
      </Gallery>
    </Slide>
  );
}
