
import React from "react";
import Slide from "../components/Slide";
export default function DesiredOnRotationSlide()
{
    return(
        <Slide>
        <div
          className="w-screen h-screen flex items-center justify-center appearance-none"
          style={{
            background:
              "radial-gradient(circle, rgba(41,99,133,1) 0%, rgba(6,19,26,1) 100%)",
            backgroundcolor: "rgba(41,99,133,1)",
          }}
        >
          <div
            className="mx:auto spotifyplaylist"
            style={{
              maxHeight: "60%",
              height: "500px",
              minWidth: "40%",
              maxWidth: "60%",
            }}
          >
            <iframe
              style={{ borderRadius: "12px" }}
              src="https://open.spotify.com/embed/playlist/2YK29czdRtZsXiE9cKMT69"
              width="100%"
              height="100%"
              allowfullscreen=""
              allow="autoplay; clipboard-write; encrypted-media; fullscreen;"
              loading="lazy"
              title="spotify"
            ></iframe>
          </div>
        </div>
      </Slide>
    )
}